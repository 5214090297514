import React from "react";
import "./ServiceImage.css";
import { NavLink } from "react-router-dom";

function ServiceImageRight({ text, text1, header, button, image }) {
  return (
    <>
      <div className="tjanster-grid-container-upper">
        <div className="tjanster-grid-item-upper-left">
          <h1>{header}</h1>
          <br />
          <p className="tjanster-grid-item-upper-left-p1">{text}</p>
          <br/>
          <p className="tjanster-grid-item-upper-left-p1">{text1}</p>
          <NavLink to="/kontakt">
            <button className="button-kontakt">{button}</button>
          </NavLink>
        </div>
        <div className="tjanster-grid-item-upper-right">
          <img
            className="tjanster-grid-item-upper-right-image"
            src={image}
            alt="Service"
          />
        </div>
      </div>
    </>
  );
}

export default ServiceImageRight;
