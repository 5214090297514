import React from "react";
import "./PriceComponent.css";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PriceComponent = () => {
  const navigate = useNavigate();

  const goToContact = (packageName) => {
    navigate("/kontakt", {
      state: { from: "PriceComponent", package: packageName },
    });

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  };

  return (
    <div>
      <h1 className="price-header">Våra Paket</h1>
      <div className="card">
        <div className="container">
          <h4 className="card-header">Liten</h4>
          <h2 className="card-price">5995:- ex. moms</h2>
          <div className="card-text">
            <p><FaCheck className="icon-card-check" size={12}/>1-5 Sidor</p>
            <p><FaCheck className="icon-card-check" size={12}/>Responsive Design</p>
            <p><FaCheck className="icon-card-check" size={12}/>Sökmotoroptimering</p>
            <p><FaCheck className="icon-card-check" size={12}/>2 -Epostadresser</p>
            <p><FaTimes className="icon-card-x" size={12}/>Unik Design</p>
            <p><FaTimes className="icon-card-x" size={12}/>1 år Kostnadsfri Hosting</p>
            <p><FaTimes className="icon-card-x" size={12}/>E Handel</p>
          </div>
          <button
            onClick={() => goToContact("Liten")} // use goToContact when the button is clicked
            className="button-price-kontakt"
          >
            Välj
          </button>
        </div>
        <div className="container">
          <h4 className="card-header">Mellan</h4>
          <h2 className="card-price">8995:- ex. moms</h2>
          <div className="card-text">
            <p><FaCheck className="icon-card-check" size={12}/>5-12 Sidor</p>
            <p><FaCheck className="icon-card-check" size={12}/>Responsive Design</p>
            <p><FaCheck className="icon-card-check" size={12}/>Avancerad Sökmotoroptimering</p>
            <p><FaCheck className="icon-card-check" size={12}/>5 -Epostadresser</p>
            <p><FaCheck className="icon-card-check" size={12}/>Unik Design</p>
            <p><FaCheck className="icon-card-check" size={12}/>1 år Kostnadsfri Hosting</p>
            <p><FaTimes className="icon-card-x" size={12}/>E Handel</p>
          </div>
          <button
            onClick={() => goToContact("Mellan")} // use goToContact when the button is clicked
            className="button-price-kontakt"
          >
            Välj
          </button>
        </div>
        <div className="container">
          <h4 className="card-header">Skräddarsydd</h4>
          <h2 className="card-price">Offert</h2>
          <div className="card-text">
            <p><FaCheck className="icon-card-check" size={12}/>Obegränsat Sidor</p>
            <p><FaCheck className="icon-card-check" size={12}/>Responsive Design</p>
            <p><FaCheck className="icon-card-check" size={12}/>Avancerad Sökmotoroptimering</p>
            <p><FaCheck className="icon-card-check" size={12}/>Obegränsat -Epostadresser</p>
            <p><FaCheck className="icon-card-check" size={12}/>Unik Design</p>
            <p><FaCheck className="icon-card-check" size={12}/>1 år Kostnadsfri Hosting</p>
            <p><FaCheck className="icon-card-check" size={12}/>E Handel</p>
          </div>
          <button
            onClick={() => goToContact("Skräddarsydd")} // use goToContact when the button is clicked
            className="button-price-kontakt"
          >
            Välj
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceComponent;
