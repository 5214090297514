import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/SebstahLogo.jpg";
import "./NavBar.css";
import CustomNavLink from "./CustomNavLink";  // Import CustomNavLink

const NavBar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleNavLinkClick = () => {
    setShowNavbar(false);
  };

  return (
    <nav className="navbar">
      <div className="container-navbar">
        <div className="logo">
          <CustomNavLink to="/">
            <img
              className="logo-size"
              src={logo}
              alt="Logo for VPkollen"
            ></img>
          </CustomNavLink>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <FontAwesomeIcon className="bars" icon={faBars} />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <CustomNavLink to="/" className="nav-links" activeClassName="active" onClick={handleNavLinkClick}>
                Start
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink to="/tjanster" className="nav-links" activeClassName="active" onClick={handleNavLinkClick}>
                Tjänster
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink to="/omoss" className="nav-links" activeClassName="active" onClick={handleNavLinkClick}>
                Om Oss
              </CustomNavLink>
            </li>
            <li>
              <CustomNavLink to="/kontakt" className="nav-links" activeClassName="active" onClick={handleNavLinkClick}>
                Kontakt
              </CustomNavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;