import React from "react";
import { Link, useLocation } from "react-router-dom";

const CustomNavLink = ({ to, children, activeClassName, ...props }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to} {...props} className={isActive ? `${props.className} ${activeClassName}` : props.className}>
      {children}
    </Link>
  );
};

export default CustomNavLink;