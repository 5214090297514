import React from "react";
import "./OmOss.css";
import thomas from "../images/tim.webp";
import nicolai from "../images/nicolai.webp";
import { FaPhone } from "react-icons/fa";
import { HiMail } from "react-icons/hi";

const OmOss = () => {
  return (
    <div className="omoss-container">
      <div className="omoss-container-h1-p">
        <h1 className="omoss-container-h1">Vem är vi?</h1>
        <p className="omoss-container-p">
          Sebstah IT är ett professionellt företag som specialiserar sig på
          webbutveckling och hemsideshosting. Med en bred erfarenhet inom
          branschen, är vårt team av erfarna webbutvecklare och designers
          välutrustade för att hjälpa dig med alla dina hemsidesbehov.
          <br />
          <br />
          Vi är ett företag som sätter kundservice i högsta prioritet och vi är
          stolta över att kunna erbjuda högkvalitativ support och expertis inom
          webbutveckling, hemsideshosting och sökoptimering. Vårt team består av
          certifierade och välutbildade webbutvecklare som har en bred kunskap
          inom allt från design och utveckling till underhåll, felsökning och
          optimering av hemsidor.
        </p>
        <h1 className="omoss-container-h1">Varför ska du välja oss?</h1>
        <p className="omoss-container-p">
          Välj Sebstah IT för vår expertis och erfarenhet inom webbutveckling,
          hemsideshosting och sökoptimering. Vi erbjuder personlig service och
          skräddarsydda lösningar som passar dina unika behov. Hos oss står
          kvalitet och funktionalitet i fokus, samtidigt som vi upprätthåller
          höga standarder. Vi tror på att leverera en komplett lösning
          för att göra processen enkel och bekväm för dig.
        </p>
      </div>
      <div className="omoss-border-line" />
      <h1 className="omoss-foretagsledning-h1">Företagsledning</h1>
      <div className="card-container-outer">
        <div className="card-container">
          <img className="thomas-image" src={thomas} alt="Thoms" />
          <div className="card-info">
            <h1 className="card-info-h1">Tim Andersson</h1>
            <p className="card-info-p">CO / Utvecklare</p>
            <p
              className="card-info-p-hover"
              onClick={() => (window.location.href = `tel:${+46736632010}`)}
            >
              <FaPhone className="icon-card-info" size={16} />
              +46 73 663 20 10
            </p>
            <p
              className="card-info-p-hover"
              onClick={() => (window.location = "mailto:tim@sebstah.com")}
            >
              <HiMail className="icon-card-info" size={16} />
              tim@sebstah.com
            </p>
          </div>
        </div>
        <div className="card-container">
          <img className="thomas-image" src={nicolai} alt="Thoms" />
          <div className="card-info">
            <h1 className="card-info-h1">Nicolai Stahl</h1>
            <p className="card-info-p">CO / Utvecklare</p>
            <p
              className="card-info-p-hover"
              onClick={() => (window.location.href = `tel:${+46735160788}`)}
            >
              <FaPhone className="icon-card-info" size={16} />
              +46 73 516 07 88
            </p>
            <p
              className="card-info-p-hover"
              onClick={() => (window.location = "mailto:nicolai@sebstah.com")}
            >
              <HiMail className="icon-card-info" size={16} />
              nicolai@sebstah.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmOss;
