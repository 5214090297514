import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Kontakt.css";

const Kontakt = () => {
  const location = useLocation();
  const [paket, setPaket] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');

  useEffect(() => {
    if (location.state) {
      if (location.state.package) {
        setPaket(location.state.package);
        setSelectedPackage(location.state.package);
      }
      console.log("this is location in kontakt", location.state);
    }
  }, [location]);

  const handleCallClick = () => {
    window.location.href = `tel:${+46736632010}`;
  };

  return (
    <div className="kontakt-container">
      <div className="kontakt-container-h1-p">
        <h1 className="kontakt-container-h1">Behöver du hjälp?</h1>
        <p className="kontakt-container-p">
          Tveka inte att kontakta oss! Vi är alltid redo att hjälpa till och
          svara på dina frågor.
        </p>
        <p className="kontakt-container-p1">
          
          <br/>
          
          <br/>
          
        </p>
        <button className="button-kontakt-container" onClick={handleCallClick}>
          +46 73 663 20 10
        </button>
        <button
          className="button-kontakt-container"
          onClick={() => (window.location = "mailto:info@sebstah.com")}
        >
          Info@sebstah.com
        </button>
      </div>
      <div className="omoss-border-line" />
      <h1 className="kontakt-kontaktaoss-h1">Kontakta oss</h1>
      {selectedPackage && <p className="chossen-package">Du har valt paket {selectedPackage} !</p>}
      <form action="https://submit-form.com/GddolKar">
        <div className="whole-form">
          <div className="form-row">
            <div className="form-group">
              <label className="form-label-kontakt" htmlFor="namn">
                Namn
              </label>
              <input
                type="text"
                id="namn"
                name="namn"
                placeholder="Namn"
                required=""
              />
            </div>
            <div className="form-group">
              <label className="form-label-kontakt" htmlFor="telefon">
                Telefon
              </label>
              <input
                type="number"
                id="telefon"
                name="telefon"
                placeholder="Telefon"
                required=""
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="form-label-kontakt" htmlFor="email">
                E-post
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required=""
              />
            </div>
            <div className="form-group">
    <label className="form-label-kontakt" htmlFor="valpaket">
      Paket
    </label>
    <input
      type="text"
      id="valpaket"
      name="valpaket"
      placeholder="Paket"
      value={paket}
      onChange={e => setPaket(e.target.value)}
      required=""
    />
  </div>
          </div>
          <div className="form-group">
            <label className="form-label-kontakt" htmlFor="meddelande">
              Meddelande
            </label>
            <textarea
              id="meddelande"
              name="meddelande"
              placeholder="Meddelande"
              required=""
            ></textarea>
          </div>
          <div className="button-container">
            <button className="sumbit-kontaktaoss" type="submit">
              Skicka in
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Kontakt;
