import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import logo from "../images/SebstahLogo.jpg";
function Footer() {
  
  return (
    <footer className="footer">
    <div className="container-footer">
      {/*<nav className="footer-elements">
        <ul>
          <NavLink to="/" exact className="footer-link">
            Start
          </NavLink>
          <NavLink to="tjanster" className="footer-link">
            Tjänster
          </NavLink>
          <NavLink to="omoss" className="footer-link">
            Om Oss
          </NavLink>
          <NavLink to="kontakt" className="footer-link">
            Kontakt
          </NavLink>
        </ul>
  </nav>*/}
      <div className="footer-row">
        <div className="footer-logo">
        <NavLink to="/">
            <img
              src={logo}
              alt="A Logo For Sebstah IT"
            ></img>
          </NavLink>
        </div>
        <div className="footer-text">Sebstah @ 2023</div>
        <div className="footer-text">Org.nr: 556814-4405 </div>
      </div>
    </div>
  </footer>
  
  );
}

export default Footer;
