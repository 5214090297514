import React from "react";

function StartComponent({ text, text1, header, header1, button, image }) {
  return (
    <>
      <div className="tjanster-grid-container-upper">
        <div className="tjanster-grid-item-upper-left">
          <h3 className="tjanster-header1">{header1}</h3>
          <h1>{header}</h1>
          <br />
          <p className="tjanster-grid-item-upper-left-p1">{text}</p>
          <br/>
          <p className="tjanster-grid-item-upper-left-p1">{text1}</p>
        </div>
        <div className="tjanster-grid-item-upper-right">
          <img
            className="tjanster-grid-item-upper-right-image"
            src={image}
            alt="Service"
          />
        </div>
      </div>
    </>
  );
}

export default StartComponent;
