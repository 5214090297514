import React, { useState, useEffect } from "react";
import Stuido from "../images/studio.webp";
import StartComponent from "../components/StartComponent";
import "./Start.css";
import { STATIC_TEXTS } from "../constants/StaticTexts";
import PriceComponent from "../components/PriceComponent";

const Start = () => {
  const texts = [
    "Transforming Businesses with Technology",
    "Expert IT Solutions for Your Success",
    "Unlocking Your Business Potential",
  ];
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div>
      <div className="start-container">
        <div className="left-side-on-image">
          {texts.map((text, index) => (
            <h1
              key={index}
              className={`changing-text ${
                textIndex === index ? "" : "fade-out"
              }`}
            >
              {text}
            </h1>
          ))}
        </div>
      </div>
      <div className="start-button-container">
        <StartComponent
          image={Stuido}
          alt="Sebstah IT Hemsidor, SEO, Hosting, Webbutveckling"
          header1={STATIC_TEXTS.headerService1}
          header={STATIC_TEXTS.headerService}
          text={STATIC_TEXTS.textService}
          text1={STATIC_TEXTS.textService1}
          button={STATIC_TEXTS.startButtonText}
        />
      </div>
      <div className="start-price-container">
          <PriceComponent/>
      </div>
    </div>
  );
};

export default Start;
