import React from "react";
import "./Tjanster.css";
import ServiceImageLeft from "../components/ServiceImageLeft.js";
import ServiceImageRight from "../components/ServiceImageRight";
import StartImage from '../images/StartImage.webp';
import Seo from '../images/Seo.webp';
import imageFörsäljning from '../images/Server.webp';

const Tjanster = () => {
  const headerInstallation = "Webbutveckling";
  const textInstallation = "Vi är ett företag som specialiserar oss på att leverera högkvalitativa webbplatser och applikationer för ditt företag eller din organisation. En välgjord webbsida är en utmärkt investering för att öka synligheten online och bidra till en mer framgångsrik digital framtid.";
  const textInstallation1 = "Vi har kvalificerade och erfarna webbutvecklare som är experter på att bygga olika typer av webbplatser och webbapplikationer. Oavsett om det är en informativ hemsida, e-handelsplattform eller en skräddarsydd webbapplikation, så har vi de kunskaper och verktyg som krävs för att säkerställa en effektiv och användarvänlig lösning.";
  const buttonInstallation = "Kontakta Oss";

  const headerService = "Sökmotoroptimering"
  const textService = "Sökmotoroptimering, eller SEO, är en komplex process och det finns många olika faktorer som påverkar din webbplats placering på sökmotorerna. Oavsett om din webbplats inte visas i sökresultaten eller om den bara inte presterar som den borde, kan vi hjälpa till att identifiera problemet och utföra nödvändiga optimeringar för att förbättra dess synlighet.";
  const textService1 = "Vår målsättning är att ge dig den bästa servicen och supporten så att du kan uppnå bättre synlighet och ranking på sökmotorerna så snabbt som möjligt. Med våra SEO-tjänster strävar vi efter att driva mer trafik till din webbplats och förbättra din online närvaro.";


  const headerFörsäljning = "Hosting"
  const textFörsäljning = "På Sebstah IT erbjuder vi förstklassiga hostingtjänster för din webbplats eller applikation. Vi stödjer ett brett utbud av hostingalternativ, inklusive delad hosting, VPS, och dedikerade servrar, anpassade efter dina unika behov.";
  const textFörsäljning1 = "Vi inser att en webbplats är en betydande investering, men det är också en investering i ditt företags digitala framtid. Därför garanterar vi kvalitet, tillförlitlighet och konkurrenskraftiga priser i alla våra hostinglösningar, för att ge din webbplats det stöd den behöver för att nå sin fulla potential.";

  return (
    <div className="tjanster-container">
      <ServiceImageLeft
        image={StartImage}
        alt="Webbutveckling"
        header={headerInstallation}
        text={textInstallation}
        text1={textInstallation1}
        button={buttonInstallation}
      />
      <div className="tjanster-border-line"/>
       <ServiceImageRight
        image={Seo}
        alt="Sökmotoroptimering"
        header={headerService}
        text={textService}
        text1={textService1}
        button={buttonInstallation}
      />
      <div className="tjanster-border-line"/>
      <ServiceImageLeft
        image={imageFörsäljning}
        alt="Hosting"
        header={headerFörsäljning}
        text={textFörsäljning}
        text1={textFörsäljning1}
        button={buttonInstallation}
      />
       <div className="tjanster-border-line-padding"/>
    </div>
  );
};

export default Tjanster;
