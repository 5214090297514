import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import Navbar from "./components/NavBar";
import Start from "./sites/Start";
import Tjanster from "./sites/Tjanster";
import OmOss from "./sites/OmOss";
import Kontakt from "./sites/Kontakt";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";

ReactGA.initialize("G-PDKBSXRWJ9");

ReactGA.send({ 
  hitType: "pageview", 
  page: window.location.pathname, 
});

function App() {

  return (
    <div className="bg-color">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/tjanster" element={<Tjanster />} />
          <Route path="/omoss" element={<OmOss />} />
          <Route path="/kontakt" element={<Kontakt />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
